export default {
    title: 'Customer Satisfaction (Task Rabbit)',
    location: '/customer/customer-satisfaction',
    tabs: [
        {
            name: 'By Country',
            link: `/customer/customer-satisfaction`
        },
    ]
}
